<template>
	<v-container class="pa-0" fluid>
		<v-slide-x-transition mode="out-in">
			<router-view></router-view>
		</v-slide-x-transition>
	</v-container>
</template>

<script>
export default {
	name: 'BlogIndex'
}
</script>

<style scoped>

</style>
